import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { Typography, Grid, Button, Tooltip } from '@material-ui/core'
import { FormGrid, SectionSubheader, MiddleGridCol } from './components'
import InvoiceICDCodeSelector from './ReferralInvoiceICDCodeSelector'
import InvoiceFileUpload from './ReferralInvoiceFileUpload'
import { ReferralInvoiceActions } from '../../actions'
import Select from '../SelectField'
import TextField from '../TextField'
import { NPIInput } from '../../components/Inputs'
import { ReferralInvoice } from './types'

import {
  STATUS_ACCEPTED,
  STATUS_DRAFT,
  STATUS_IGNORED,
  STATUS_REJECTED,
  STATUS_SUBMITTED,
} from './statuses'
import ManagedDateInput from '../Inputs/managedDateInput'
import dateTime from '../../utils/dateTime'

// Field to correctly display a billing address
// const BillingAddressField = ({ billingEntity }: any) => {
//   if (!billingEntity) return null

//   const { RemittanceAddress } = billingEntity
//   const { Address1, Address2, City, State, Zip } = RemittanceAddress || {}

//   if (!Address1) return null

//   return (
//     <div>
//       <Typography variant="subtitle2" style={{ paddingBottom: 10 }}>
//         <strong>Remittance Address</strong>
//       </Typography>
//       <Typography variant="subtitle1">{Address1 || ''}</Typography>
//       <Typography variant="subtitle1">{Address2 || ''}</Typography>
//       <Typography variant="subtitle1">
//         {City || State || Zip
//           ? `${City || ''}, ${State || ''} ${Zip || ''}`
//           : ''}
//       </Typography>
//     </div>
//   )
// }

interface InvoiceDetailsProps {
  referralInvoice: ReferralInvoice
  form: any
  editable: boolean
  showErrors: boolean
}

const InvoiceDetails: FC<InvoiceDetailsProps> = ({
  referralInvoice,
  form,
  editable,
  showErrors,
}: InvoiceDetailsProps) => {
  const [selectedBiller, setSelectedBiller] = useState<any>({ ID: null })
  const [billerList, setBillerList] = useState<
    { ID: number; TIN: string; [key: string]: any }[]
  >([])
  const memodBillerList = React.useMemo(() => {
    return [
      { value: null, name: 'Select a TIN' },
      ...billerList.map((x: any) => ({
        value: x.ID,
        name: x.TIN,
      })),
    ]
    // return [{ ID: null, TIN: 'Select a TIN' }, ...billerList].map(
    //   (item: any) => ({
    //     value: item.ID,
    //     name: item.TIN,
    //   })
    // )
  }, [billerList])

  const manageBillerSelection = (billingEntity: any) => {
    setSelectedBiller(billingEntity)
    // fill in organization NPI if empty and the selected billing entity has an NPI in our system
    if (
      !form.data.OrganizationNPI &&
      billingEntity &&
      billingEntity.NPI !== ''
    ) {
      form.setters.OrganizationNPI(billingEntity.NPI)
      // woof, timing hack here. Form setters are not syncronous and we can end up wiping out changes
      // if called in quick succession
      form.data.OrganizationNPI = billingEntity.NPI
    }
    form.setters.BillingEntity2ID(billingEntity.ID)
  }

  useEffect(() => {
    ReferralInvoiceActions.getBillingEntities({
      organizationIDs: [referralInvoice.Referral.OrganizationID],
    }).then((res: any) => {
      setBillerList(res?.Data || [])
      // Set the initial billing entity based on if one came through from the record
      if (referralInvoice.BillingEntity2ID) {
        const billingEntity = res?.Data?.find(
          (d: any) => d.ID === referralInvoice.BillingEntity2ID
        )
        if (billingEntity) {
          setSelectedBiller(billingEntity)
        }
        return
      }
      if (res?.Data?.length === 1) {
        // Biller did not come through from the record, and there is only one option to choose
        // from, so auto-select it. NOTABLY: if there are 2 or more options, we do NOT want to
        // blindly choose the first record... we want to force the user to pick one.
        manageBillerSelection(res?.Data?.[0])
      }
      return
    })
  }, [])

  function doUseNewTin() {
    for (let i = 0, len = billerList.length; i < len; i++) {
      // @ts-ignore
      if (billerList[i].UUID === referralInvoice.LinkedBillingEntity2.UUID) {
        const be = billerList[i] as any
        setSelectedBiller(be)
        if (!!be.NPI) {
          form.setters.OrganizationNPI(be.NPI)
          form.data.OrganizationNPI = be.NPI
        }
        form.setters.BillingEntity2ID(be.ID)
        return
      }
    }
  }

  function showNeedUpdateBillingEntity() {
    return (
      !!referralInvoice.LinkedBillingEntity2?.ID &&
      !referralInvoice.LinkedBillingEntity2.IsLatest &&
      form.data.BillingEntity2ID === referralInvoice.LinkedBillingEntity2.ID
    )
  }

  return (
    <FormGrid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">Invoice Details</Typography>
        <p style={{ marginTop: '0px', fontSize: '18px' }}>
          Invoice Number: {referralInvoice.ID}
        </p>
        <SectionSubheader>
          {referralInvoice.ReferralInvoiceStatusID === STATUS_DRAFT ? (
            <em>
              This invoice is a draft. Fill out the details below then press
              submit to send it to ZERO for review.
            </em>
          ) : null}
          {referralInvoice.ReferralInvoiceStatusID === STATUS_SUBMITTED ? (
            <em>This invoice has been submitted and cannot be edited.</em>
          ) : null}
          {referralInvoice.ReferralInvoiceStatusID === STATUS_IGNORED ? (
            <em>
              This invoice has been ignored. If you need to edit it, click Save
              In Draft Mode below.
            </em>
          ) : null}
          {referralInvoice.ReferralInvoiceStatusID === STATUS_REJECTED ? (
            <em>
              This invoice has been rejected by ZERO. Please review notes, make
              edits and re-submit.
            </em>
          ) : null}
          {referralInvoice.ReferralInvoiceStatusID === STATUS_ACCEPTED ? (
            <em>
              This invoice has been accepted by ZERO and can no longer be
              edited.
            </em>
          ) : null}
        </SectionSubheader>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          spacing={4}
          // direction="row"
          // justify="space-between"
          alignItems="flex-start">
          <Grid item xs={3}>
            <ManagedDateInput
              label="Date of Service"
              disabled={!editable}
              value={form.data.DateOfService}
              setter={({ name, value }) => form.setters.DateOfService(value)}
              error={showErrors && !!form.errors.DateOfService}
            />
            {!!form?.errors?.DateOfService && (
              <Typography variant="caption" color="error">
                {form.errors.DateOfService}
              </Typography>
            )}
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Patient Account Number"
              value={form.data.PatientAccountNumber}
              onChange={form.setters.PatientAccountNumber}
              disabled={!editable}
            />
          </Grid>
          <Grid item xs="auto">
            <Tooltip
              title={
                <span style={{ fontSize: '0.8rem' }}>
                  By selecting a TIN below, this field will auto-populate.
                </span>
              }
              placement="top"
              arrow>
              <NPIInput
                value={form.data.OrganizationNPI}
                disabled={!editable}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  form.setters.OrganizationNPI(e.target.value)
                }
                label="Organization NPI"
              />
            </Tooltip>
          </Grid>
          <Grid item xs={3}>
            <Select
              label="Facility NPI"
              value={form.data.PracticeFacilityNPI}
              onChange={(npi: string) => form.setters.PracticeFacilityNPI(npi)}
              disabled={!editable}
              options={referralInvoice.PracticeFacilityNPIs.map(
                (npi: string) => ({
                  name: npi,
                  value: npi,
                })
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        justifyContent="space-between"
        alignItems="center">
        <Grid item xs="auto">
          {!editable && (
            <div>
              <Typography
                variant="caption"
                color="textSecondary"
                style={{ paddingBottom: 10 }}>
                <strong>TIN</strong>
              </Typography>
              <Typography>
                {referralInvoice.LinkedBillingEntity2?.TIN}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {referralInvoice.LinkedBillingEntity2?.Name}
                <br />
                {referralInvoice.LinkedBillingEntity2?.DisplayAddress}
              </Typography>
              {referralInvoice.LinkedBillingEntity2?.IsCurrentlyActive ===
                false && (
                <Typography
                  variant="caption"
                  color="textSecondary"
                  style={{
                    background: '#f1f1f1',
                    display: 'block',
                    padding: '0.35rem',
                    fontWeight: 600,
                    borderRadius: '5px',
                    marginTop: '0.5rem',
                    maxWidth: 410,
                  }}>
                  ZERO's records have been updated since this TIN was selected.
                </Typography>
              )}
            </div>
          )}

          {editable &&
            (showNeedUpdateBillingEntity() ? (
              <div>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  style={{ paddingBottom: 10 }}>
                  <strong>TIN</strong>
                </Typography>
                <Typography
                  variant="caption"
                  style={{
                    background: '#f0ca27',
                    display: 'block',
                    padding: '0.35rem',
                    fontWeight: 600,
                    borderRadius: '5px',
                    marginTop: '0.5rem',
                    maxWidth: 410,
                  }}>
                  ZERO has new information on file for the TIN previously
                  configured on this invoice (new remittance address,
                  Organization NPI, or W9 on file), which requires manual
                  review. Click 'Update TIN' below to auto-populate the new
                  info, then review the TIN and associated Organization NPI
                  above.
                  <div style={{ paddingTop: '0.35rem' }}>
                    <Button
                      onClick={doUseNewTin}
                      fullWidth
                      variant="contained"
                      size="small">
                      Update TIN
                    </Button>
                  </div>
                </Typography>
              </div>
            ) : (
              <div style={{ maxWidth: 410 }}>
                <Select
                  label="TIN"
                  value={form.data.BillingEntity2ID}
                  onChange={(id: number) => {
                    if (!id) {
                      form.setters.BillingEntity2ID(null)
                      form.setters.OrganizationNPI('')
                      setSelectedBiller({ ID: null })
                      return
                    }
                    const billingEntity = billerList.find(
                      (d: any) => d.ID === id
                    )
                    manageBillerSelection(billingEntity)
                  }}
                  disabled={!editable}
                  options={memodBillerList}
                  // options={[null, ...billerList].map((item: any) => {
                  //   if (!item) {
                  //     return {
                  //       name: 'Select a TIN',
                  //       value: null,
                  //     }
                  //   }
                  //   return { value: item.ID, name: item.TIN }
                  // })}
                />
                {!!selectedBiller && (
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    style={{ paddingTop: '0.25rem', display: 'block' }}>
                    <span>{selectedBiller.Name}</span>
                    <br />
                    {selectedBiller.DisplayAddress ? (
                      <span>
                        <strong>Remittance Address:</strong>{' '}
                        {selectedBiller.DisplayAddress}
                      </span>
                    ) : (
                      <span>
                        ZERO does not have a remittance address on file for this
                        TIN.
                      </span>
                    )}
                  </Typography>
                )}
                {selectedBiller?.IsCurrentlyActive === false && (
                  <Typography
                    variant="caption"
                    style={{
                      background: '#f0ca27',
                      display: 'block',
                      padding: '0.35rem',
                      borderRadius: '5px',
                      marginTop: '0.5rem',
                    }}>
                    ZERO's records indicate this TIN (or the remittance address)
                    may be outdated as of &nbsp;
                    {dateTime
                      .parse(selectedBiller.DateEnd)
                      .format(dateTime.formats.AmericanDate)}
                    .{' '}
                    <strong>
                      Please double-check the TIN, remittance address, and
                      Organization NPI (above) are correct for the Date Of
                      Service before submitting.
                    </strong>
                  </Typography>
                )}
              </div>
            ))}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div style={{ paddingTop: 12 }}>
          <InvoiceFileUpload
            id={referralInvoice.ID}
            files={referralInvoice.Files}
          />
        </div>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 8 }}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start">
          <Grid item xs={8}>
            <Typography
              variant="subtitle2"
              style={{ marginTop: 3, paddingBottom: 8 }}>
              <strong>Add ICD Codes</strong>
            </Typography>
            <InvoiceICDCodeSelector
              id={referralInvoice.ID}
              codes={referralInvoice.ICD10Codes}
              readOnly={!editable}
            />
          </Grid>
          <Grid item xs={6} />
        </Grid>
      </Grid>
    </FormGrid>
  )
}

export default InvoiceDetails
